import React from 'react';
import { useLocation } from "react-router-dom";
import { Route, Redirect } from 'react-router-dom';
import { isLogin } from '../../services/Auth';


function PrivateRoute({ component: Component, ...rest }) {
    let location = useLocation();

    React.useEffect(() => {
        localStorage.setItem("redirectTo", location.pathname);
    }, []);

    return (
        <Route {...rest} render={props => {
            const isLoggedIn = isLogin();
            if (!isLoggedIn) {
                console.log(location);
                // not logged in so redirect to login page with the return url
                return <Redirect to={{ pathname: '/sign-in', state: { from: props.location } }} />
            }

            // authorized so return component
            return <Component {...props} />
        }} />
    );
}

export { PrivateRoute };