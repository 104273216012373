import React from 'react';
import { addConfig } from '../../AuthConfig';

function SignInStart(props) {

    React.useEffect(() => {
        console.log(process.env);
        let state = _guid(); // _guid() is a helper function in the sample
        localStorage.setItem("simple.state", state);
        localStorage.removeItem("simple.error");
        // Go to the Azure AD authorization endpoint
        let queryParams = {
            client_id: addConfig.clientId,
            response_type: "id_token token",
            response_mode: "fragment",
            scope: "https://graph.microsoft.com/User.Read email openid profile offline_access Files.Read Files.ReadWrite Files.Read.All Files.ReadWrite.All Sites.Read.All Sites.ReadWrite.All",
            redirect_uri: window.location.origin + "/sign-in/sign-in-end",
            nonce: _guid(),
            state: state
        };

        let authorizeEndpoint = `https://login.microsoftonline.com/${addConfig.TenantId}/oauth2/v2.0/authorize?${toQueryString(queryParams)}`;
        window.location.assign(authorizeEndpoint);
    }, []);

    return (<></>);
}

export function _guid() {
    var val = Math.floor(1000 + Math.random() * 9000);
    return `${val}`;
}

export function toQueryString(queryParams) {
    let encodedQueryParams = [];
    for (let key in queryParams) {
        encodedQueryParams.push(key + "=" + encodeURIComponent(queryParams[key]));
    }
    return encodedQueryParams.join("&");
}

export default SignInStart;