import React from "react";
import styles from "./signin.module.scss";
import { Row, Col, Button } from "react-bootstrap";

const microsoft = "https://hgipportal.blob.core.windows.net/hg-teams-app/microsoft.svg";

function SignIn(props) {
    const handleSignin = () => {
        props.history.push({
            pathname: `/sign-in/sign-in-start`
        });
    }
    return (
        <React.Fragment>
            <div className="container-fluid px-4">
                <Row className={`${styles.container_center}`}>

                    <div className="col-md-6 col-lg-3 d-flex justify-content-center">
                        <a
                            className="btn btn-outline-dark"
                            role="button"
                            onClick={handleSignin}
                        >
                            <img
                                alt="Favorite"
                                src={microsoft}
                                className={`${styles.microsoft_img}`}
                            />
                            Login with Microsoft
                        </a>
                    </div>
                </Row>
            </div>
        </React.Fragment >
    );
}

export default SignIn;