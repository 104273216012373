import React from 'react';
import { Row, Col } from "react-bootstrap";
import styles from "../allcoegrid.module.scss";
import textgray from "../../../assets/images/textgray.svg";
import pingray from "../../../assets/images/pin-gray.svg";
import powerpointgray from "../../../assets/images/powerpoint-gray.svg";
import videogray from "../../../assets/images/video-gray.svg";

function IdeaCardArtifactList(props) {

    return (
        <React.Fragment>
            <Row className={`${styles.ico_section}`}>
                <Col md="auto" className="pe-0">
                    <span>
                        <img
                            data-test="IdeaCardArtifactList-text"
                            alt="text"
                            src={textgray}
                            className={`${styles.img_text}`}
                        />
                    </span>
                    <span data-test="IdeaCardArtifactList-text-count" >{props.data.doc_count}</span>
                </Col>
                <Col md="auto" className="pe-0">
                    <span>
                        <img
                            data-test="IdeaCardArtifactList-video"
                            alt="video"
                            src={videogray}
                            className={`${styles.img_video}`}
                        />
                    </span>
                    <span data-test="IdeaCardArtifactList-video-count">{props.data.mp4_count}</span>
                </Col>
                <Col md="auto" className="pe-0">
                    <span>
                        <img
                            data-test="IdeaCardArtifactList-pin"
                            alt="pin"
                            src={pingray}
                            className={`${styles.img_pin}`}
                        />
                    </span>
                    <span data-test="IdeaCardArtifactList-pin-count">{props.data.link_count}</span>
                </Col>
                <Col md="auto" className="pe-0">
                    <span>
                        <img
                            data-test="IdeaCardArtifactList-powerpoint"
                            alt="powerpoint"
                            src={powerpointgray}
                            className={`${styles.img_powerpoint}`}
                        />
                    </span>
                    <span data-test="IdeaCardArtifactList-powerpoint-count">{props.data.pdf_count}</span>
                </Col>
            </Row>
        </React.Fragment>
    );
}

export default IdeaCardArtifactList;