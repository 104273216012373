import React from 'react';
import { connect } from 'react-redux';
import styles from "../Home/home.module.scss";
import { Card, Row, Col, Container } from "react-bootstrap";
import allips from "../../assets/images/all-coes.svg";
import CoeCard from '../../common/CoeCard/CoeCard';
import { apis } from '../../services/Api';


const Home = (props) => {

  let [categoryTypeList, setCategoryTypeList] = React.useState([]);
  let [categoryList, setCategoryList] = React.useState([]);
  let [selectedCategoryType, setSelectedCategoryType] = React.useState('all');
 
  React.useEffect(() => {
    //call api for display data at home page
    apiCall();
  }, [])

  const apiCall = async (id) => {
    let result = await apis.getCategories(id || 'all');
    setCategoryList(result.data);
   let {data}= await apis.getCategoryTypes();
   setCategoryTypeList(data);
  }

  const goToIdeas = (cid, name) => {
    props.history.push({
      pathname: `/${cid}/ideas`
    });
  }

  //every time dropdown input change , api will call
  const handleCoeChnage = async (e) => {
    setCategoryList([...[]]);
    apiCall(e.target.value);
    setSelectedCategoryType(e.target.value)
  }

  return (
    <>
      <div className="container-fluid py-5 px-4">
        <Row className="mb-3">
          <Col md={2} lg={2} className="dropdown-no-border">
            <select className="form-select" id="dropdown-all-coe" onChange={(e) => handleCoeChnage(e)}>
              <option active value="all"  >All</option>
              {categoryTypeList.map(e => <option value={e.id}  >{e.name}</option>)}
            </select>
          </Col>

          <Col lg={10}></Col>
        </Row>
        <Row xs={1} md={1} lg={3} className="g-4">
          {selectedCategoryType==='all' ?
          <Col>
            <Card className={`mb-3 ${styles.card_main}`} onClick={() => goToIdeas('allips', 'allips')} id="Home-all-coe">
              <Card.Body className="d-flex">
                <Container>
                  <Row>
                    <Col md={12} lg={4} xl={3} xxl={2} className={`${styles.card_image}`}>
                      {" "}
                      <img alt="All IPs" src={allips} />
                    </Col>
                    <Col
                      md={12}
                      lg={8}
                      xl={9}
                      xxl={10}
                      className={`d-flex align-items-center ${styles.card_title}`}
                    >
                      All IPs
                    </Col>
                  </Row>
                </Container>
              </Card.Body>
              <Card.Footer className={`${styles.c_footer}`}>
                <div className={`${styles.f_text}`}>Total Artifacts: {props.allCoe.idea_count}</div>
              </Card.Footer>
            </Card>
          </Col>:""
          }
          {categoryList.map(e =>
            <Col key={e.id}>
              <CoeCard data={e} goToIdeas={goToIdeas}></CoeCard>
            </Col>
          )}
        </Row>
      </div>
    </>
  );
};

const mapStateToProps = state => {
  return {
    allCoe: state.defaultCoe
  }
}

export default connect(mapStateToProps)(Home);
