// Parse hash parameters into key-value pairs
export default function HashParameters() {
    let hashParams = {};
    window.location.hash.substr(1).split("&").forEach(function (item) {
        let s = item.split("="),
            k = s[0],
            v = s[1] && decodeURIComponent(s[1]);
        hashParams[k] = v;
    });
    return hashParams;
}