import React from 'react';
import styles from '../../component/Home/home.module.scss';
import { Card, Row, Col, Container } from "react-bootstrap";

function CoeCard(props) {
    return (
        <React.Fragment>
            <Card className={`mb-3 ${styles.card_main}`} onClick={() => props.goToIdeas(props.data.id, props.data.title)} data-test="CoeCard-card">
                <Card.Body className="d-flex">
                    <Container>
                        <Row>
                            <Col md={12} lg={4} xl={3} xxl={2} className={`${styles.card_image}`}>
                                {" "}
                                <img alt="Content CoE" src={props.data.image} />
                            </Col>
                            <Col
                                md={12}
                                lg={8}
                                xl={9}
                                xxl={10}
                                className={`d-flex align-items-center ${styles.card_title}`}
                            >
                                {props.data.title}
                            </Col>
                        </Row>
                    </Container>
                </Card.Body>
                <Card.Footer className={`${styles.c_footer}`}>
                    <div className={`${styles.f_text}`}>Total Artifacts: {props.data.idea_count}</div>
                </Card.Footer>
            </Card>
        </React.Fragment>
    );
}

export default CoeCard;