import axios from 'axios';
const baseUrl = `${process.env.REACT_APP_API_ENDPOINT}`;

async function apiAuthenticate(accessToken) {
    // authenticate with the api using a facebook access token,
    // on success the api returns an account object with a JWT auth token
    // const response = await axios.post(`${baseUrl}/user/auth/token`, {
    //     'tid': tid,
    //     'idToken': idToken
    // });
    const response = await axios.post(`${baseUrl}/user/auth/token`, {
        'accessToken': accessToken
    });
    const result = response.data;
    return result;
}

async function getCategories(id ='all') {
    const response = await axios.get(`${baseUrl}/categories/${id}`);
    const result = response.data;
    return result;
}

async function getCategoryTypes(id ='all') {
    const response = await axios.get(`${baseUrl}/category/types`);
    const result = response.data;
    return result;
}

async function addIdea(cid, formData) {
    const response = await axios.post(`${baseUrl}/category/${cid}/idea`, formData);
    const result = response.data;
    return result;
}

async function searchIdea(text) {
    const response = await axios.get(`${baseUrl}/ideas/${text}`);
    const result = response.data;
    return result;
}


async function getIdeas(id, page = 1, limit = 10, text, tags, lobs, sortBy) {
    let url = `${baseUrl}/category/${id}/ideas?page=${page}&limit=${limit}`;
    url = text ? url + `&text=${text}` : url;
    url = tags.length > 0 ? url + `&tags=${tags.join()}` : url;
    url = lobs.length > 0 ? url + `&lobs=${lobs.join()}` : url;
    url = sortBy ? url + `&sort_by=${sortBy}` : url;
    const response = await axios.get(url);
    const result = response.data;
    return result.data;
}

async function deleteIdeas(cid, iid) {
    const response = await axios.delete(`${baseUrl}/category/${cid}/idea/${iid}`);
    const result = response.data;
    return result.data;
}
/**
 *
 *
 * @param {*} id
 * @param {*} isLiked -if user like it value is 1 otherwise value is 0
 * @return {*} 
 */
async function like(cid, iid, isLiked) {
    const response = await axios.post(`${baseUrl}/category/${cid}/idea/${iid}/like`, {
        "is_liked": isLiked
    });
    const result = response.data;
    return result.data[0];
}

/**
 * @param {*} id
 * @param {*} isFavorite -if user Favorite it value is 1 otherwise value is 0
 * @return {*} 
 */
async function favorite(cid, iid, isFavorite) {
    const response = await axios.post(`${baseUrl}/category/${cid}/idea/${iid}/favorite`, {
        "is_favorite": isFavorite
    });
    const result = response.data;
    return result.data[0];
}

async function getIdea(cid, iid) {
    const response = await axios.get(`${baseUrl}/category/${cid}/idea/${iid}`);
    const result = response.data;
    return result;
}

async function updateIdea(cid, iid, formData) {
    const response = await axios.put(`${baseUrl}/category/${cid}/idea/${iid}`, formData);
    const result = response.data;
    return result;
}

async function getTags() {
    const response = await axios.get(`${baseUrl}/tags`);
    const result = response.data;
    return result;
}

async function getLobs() {
    const response = await axios.get(`${baseUrl}/lobs`);
    const result = response.data;
    return result;
}

async function addComment(cid, iid, comment_txt, is_client_feedback) {
    const response = await axios.post(`${baseUrl}/category/${cid}/idea/${iid}/comment`, { comment_txt, is_client_feedback });
    const result = response.data;
    return result.data[0];
}

async function getComments(cid, iid) {
    const response = await axios.get(`${baseUrl}/category/${cid}/idea/${iid}/comments`);
    const result = response.data;
    return result;
}

async function editComments(cid, iid,data) {
    const response = await axios.put(`${baseUrl}/category/${cid}/idea/${iid}/comments`,data);
    const result = response.data;
    return result;
}

async function commentLike(cid, iid, cmdid, is_liked) {
    const response = await axios.post(`${baseUrl}/category/${cid}/idea/${iid}/comment/${cmdid}/like`, { is_liked });
    const result = response.data;
    return result.data[0];
}

async function getUserDetails() {
    const response = await axios.get(`${baseUrl}/user/about`);
    return response.data;
}

async function getFileList(id, root) {
    let response;
    if (root) {
        response = await axios.get(`https://graph.microsoft.com/v1.0/groups/${process.env.REACT_APP_GROUP_ID}/drive/root/children`);
    } else {
        response = await axios.get(`https://graph.microsoft.com/v1.0/groups/${process.env.REACT_APP_GROUP_ID}/drive/items/${id}/children`);
    }
    return response.data;
}

async function getFileDetails(id) {
    const response = await axios.get(`https://graph.microsoft.com/v1.0/groups/${process.env.REACT_APP_GROUP_ID}/drive/items/${id}`);
    return response.data;
}

export const apis = {
    apiAuthenticate,
    getCategories,
    getCategoryTypes,
    searchIdea,
    addIdea,
    getIdeas,
    deleteIdeas,
    like,
    favorite,
    getIdea,
    updateIdea,
    getTags,
    getLobs,
    addComment,
    getComments,
    editComments,
    commentLike,
    getUserDetails,
    getFileList,
    getFileDetails
};





